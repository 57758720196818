import { ReactComponent as Logo } from "./svg/logo.svg";
import Reservation from "./components/Reservation";
import GoToReservation from "./components/GoToReservation";
import ButtonReservation from "./components/ButtonReservation";
import Reveal from "./components/AnimReveal";
import useData from "./hooks/useData";
import ErrorPage from "./components/ErrorPage";

export default function Home() {
  const { data, loading } = useData("/singletons/get/page_home");
  if (loading) return null;
  if (!loading && !data) return <ErrorPage />;

  return (
    <div>
      <div className="bg-purple">
        <div
          className="bg-center bg-cover"
          style={{
            backgroundImage: `url(${
              process.env.REACT_APP_BASE_URL + data.banner.path
            })`,
            height: "calc(100vh - 50px)",
          }}
        />
        <GoToReservation />
        <div className="container pt-6 pb-10 text-center text-white md:pb-0 md:pt-12">
          <div className="flex justify-center pb-3">
            <Reveal>
              <Logo />
            </Reveal>
          </div>
          <div className="text-20 md:text-48 md:mb-12">
            <Reveal>{data.headline}</Reveal>
          </div>
          <ButtonReservation />
          <div className="block md:flex">
            <div className="w-6/12 pl-3 md:pr-3 md:pl-0">
              <Reveal>
                <img
                  src={process.env.REACT_APP_BASE_URL + data.image_1.path}
                  width="100%"
                  alt="menu-1"
                />
              </Reveal>
            </div>
            <div className="block w-full pt-4 pl-3 pr-3 md:hidden">
              <div className="pl-3">
                <Reveal>
                  <img
                    src={process.env.REACT_APP_BASE_URL + data.image_2.path}
                    width="100%"
                    alt="menu-1"
                  />
                </Reveal>
              </div>
            </div>
            <div className="w-full pt-4 pl-3 pr-3 text-left md:w-6/12">
              <div className="pb-3 text-32 md:text-48 font-f-2">
                <Reveal>{data.title}</Reveal>
              </div>
              <div className="text-16 md:text-21">
                <Reveal>
                  <div dangerouslySetInnerHTML={{ __html: data.description }} />
                </Reveal>
              </div>
            </div>
          </div>
          <div className="hidden mt-10 pb-17 md:block">
            <Reveal>
              <img
                src="/img/home-2a-min.jpg"
                width="100%"
                className="w-10/12 ml-auto mr-auto"
                alt="menu-2"
              />
            </Reveal>
          </div>
        </div>
      </div>
      <div className="">
        <Reservation />
      </div>
      <img
        src={process.env.REACT_APP_BASE_URL + data.image_3.path}
        width="100%"
        alt="home-banner"
      />
    </div>
  );
}
