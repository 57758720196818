import { Link, useLocation } from "react-router-dom";
import { ReactComponent as Menu } from "../svg/menu.svg";
import { ReactComponent as Close } from "../svg/close.svg";
import { ReactComponent as FullLogo } from "../svg/logo-with-name.svg";

import { ReactComponent as Instagram } from "../svg/instagram.svg";
import { ReactComponent as Youtube } from "../svg/youtube.svg";
import { useEffect, useState } from "react";
import Reveal from "../components/AnimReveal";
import { useSpring, config } from "react-spring";

function detectMob() {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
}

export default function Header() {
  const [showMenu, setShowMenu] = useState(true);
  const location = useLocation();
  const [y, setY] = useSpring(() => ({
    immediate: true,
    config: config.default,
    y: 0,
    onFrame: (props: any) => {
      window.scroll(0, props.y);
    },
  }));

  useEffect(() => {
    if (showMenu) {
      document.getElementsByTagName("body")[0].classList.add("show-menu");
    } else {
      document.getElementsByTagName("body")[0].classList.remove("show-menu");
    }
  }, [showMenu]);

  useEffect(() => {
    setShowMenu(false);
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className="relative top-0 left-0 right-0 z-50 flex items-center justify-center pt-3 md:pointer-events-none header">
      <button
        onClick={() => setShowMenu(!showMenu)}
        className="absolute top-0 left-0 z-10 z-50 pt-2 pl-2 pointer-events-auto md:pt-4 md:pl-4"
      >
        {showMenu ? <Close /> : <Menu className="menu-pink" />}
      </button>
      <Link to="/" className="pointer-events-auto md:block header-logo">
        <FullLogo />
      </Link>
      <div className="fixed top-0 bottom-0 left-0 right-0 pt-10 pl-3 pointer-events-auto md:pt-12 md:pb-12 md:pr-3 main-menu bg-lightPink md:pl-13">
        <Reveal delay={300} notOnce>
          <Link
            to="/"
            onClick={() => setShowMenu(false)}
            className="block pb-4 text-16 md:text-40 font-f-2"
          >
            Jiva Bestari
          </Link>
        </Reveal>
        <div className="md:grid grid-cols-2 gap-4">
          {/*<Link
            className="block mb-2 underline md:mb-0 md:inline-block text-32 md:text-64"
            onClick={() => setShowMenu(false)}
            to="/jelajah-rasa-dewantara"
          >
            <Reveal delay={300} notOnce>
              Jelajah Rasa Dewantara
            </Reveal>
            </Link>*/}
          <Link
            onClick={() => setShowMenu(false)}
            className="block mb-2 underline md:mb-0 md:inline-block text-32 md:text-64"
            to="/menu"
          >
            <Reveal delay={300} notOnce>
              Menu
            </Reveal>
          </Link>
          <Link
            onClick={() => setShowMenu(false)}
            className="block mb-2 underline md:mb-0 md:inline-block text-32 md:text-64"
            to="/info-sekolah"
          >
            <Reveal delay={300} notOnce>
              Info Sekolah
            </Reveal>
          </Link>
          <Link
            onClick={() => setShowMenu(false)}
            className="block mb-2 underline md:mb-0 md:inline-block text-32 md:text-64"
            to="/hubungi-kami"
          >
            <Reveal delay={300} notOnce>
              Hubungi Kami
            </Reveal>
          </Link>
          <Link
            onClick={() => {
              setShowMenu(false);
              if (detectMob()) {
                const w: any = window;
                w.showReservation();
                return;
              }
              const el: any = document.querySelectorAll(".block-reservation");
              const sel = el[1] || el[0];
              setY({ y: sel.offsetTop });
            }}
            className="block mb-2 underline md:mb-0 md:inline-block text-32 md:text-64"
            to="/"
          >
            <Reveal delay={300} notOnce>
              Reservasi
            </Reveal>
          </Link>
        </div>
        <Reveal delay={300} notOnce>
          <div className="flex pt-5 md:pt-10 md:pb-5">
            <a
              href="https://www.instagram.com/jivabestari/"
              target="_blank"
              title="instagram"
              rel="noreferrer"
              className="inline-block mr-2"
            >
              <Instagram />
            </a>
            <a
              href="https://www.youtube.com/channel/UCWOPL-jzz_EavruiifHzF1g"
              target="_blank"
              rel="noreferrer"
              title="youtube"
              className="inline-block mr-2"
            >
              <Youtube />
            </a>
          </div>
        </Reveal>
      </div>
    </div>
  );
}
