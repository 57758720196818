import { ReactComponent as Instagram } from "../svg/instagram.svg";
import { ReactComponent as Youtube } from "../svg/youtube.svg";
import Reveal from "../components/AnimReveal";

export default function Footer() {
  return (
    <div style={{ backgroundColor: "#F4F4F4" }}>
      <div className="flex pt-4 pl-3 pr-3 text-16 md:text-24 md:pt-10 md:pl-10 md:pr-10">
        <div>
          <div className="mb-2 md:pb-4">
            <Reveal>Jam Operasional</Reveal>
          </div>
          <Reveal>
            Senin-Minggu <br /> 11.00 - 17.00
          </Reveal>
        </div>
        <div className="pl-5 md:pl-15">
          <div className="mb-2 md:pb-4">
            <Reveal>Lokasi</Reveal>
          </div>
          <Reveal>
            Jalan Jepara Kaliwungu KM 4.5 <br /> Kaliwungu, Kudus, <br />
            Jawa Tengah 59332 <br />
            Indonesia
          </Reveal>
          <Reveal delay={200}>
            <div
              className="flex"
              style={{ paddingBottom: 50, paddingTop: 100 }}
            >
              <a
                href="https://www.instagram.com/jivabestari/"
                title="instagram"
                rel="noreferrer"
                target="_blank"
                className="inline-block mr-2"
              >
                <Instagram />
              </a>
              <a
                href="https://www.youtube.com/channel/UCWOPL-jzz_EavruiifHzF1g"
                title="youtube"
                rel="noreferrer"
                target="_blank"
                className="inline-block mr-2"
              >
                <Youtube />
              </a>
            </div>
          </Reveal>
        </div>
      </div>
      <hr style={{ borderColor: "rgba(0, 0, 0, 0.4)" }} />
      <div className="justify-between pt-4 pb-4 pl-3 pr-3 font-light font-f-2 text-13 md:text-24 md:flex md:pl-10 md:pr-10">
        <div>Copyright © 2021 Jiva Bestari All rights reserved</div>
      </div>
    </div>
  );
}
