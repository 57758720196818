// import { useEffect, useState } from "react";
// import Select from "./Select";
// import Input from "./Input";
// @ts-ignore
// import DatePicker from "react-datepicker";
// import { ReactComponent as Chevron } from "../svg/chevron-down.svg";
// import "react-datepicker/dist/react-datepicker.css";

// function validateEmail(email: string) {
//   const re =
//     /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
//   return re.test(String(email).toLowerCase());
// }

export default function Reservation(){
    return (
    <a href="//api.whatsapp.com/send?phone=6282138225320&text=Halo Jiva Bestari, apakah boleh minta form reservasi? Terima kasih" className="font-f-2 text-24 reservation" target="_blank" rel="noreferrer">
      <div className="block-reservation bg-pink md:pb-8 mt-2 pt-4 pb-4 md:pt-8" style={{ display: "flex", justifyContent: "center", alignItems: "center"}}>
          Reservasi Via Whatsapp
      </div>
    </a>
    )
  }


// export default function Resevation() {
//   const [active, setActive] = useState("reservasi");
//   const [showModal, setShowModal] = useState(false);
//   const [showError, setShowError] = useState(false);
//   const [visitors, setVisitors] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [formValidation, setFormValidation] = useState({
//     email: false,
//     touched: false,
//   });

//   const [formData, setFormData] = useState<any>({
//     jenis: "Regular",
//     tanggal: "",
//     jam: "",
//     tamu: "",
//     acara: "",
//     name: "",
//     phone: "",
//     email: "",
//   });
//   const date = new Date();

//   useEffect(() => {
//     if (formData.tanggal === "") return;
//     fetch(
//       [
//         process.env.REACT_APP_API_URL,
//         "/forms/get/reservation?token=d8acbd67a12b4037eef7f9a4cd692f&d=" +
//           formData.tanggal.getDate(),
//         formData.tanggal.getMonth() + 1,
//         formData.tanggal.getFullYear(),
//       ].join("/")
//     ).then(async (res) => {
//       const json = await res.json();
//       setVisitors(json.entries);
//     });
//   }, [formData.tanggal]);

//   let listTamu: any =
//     formData.jenis === "Regular" ? [...Array(50)] : [...Array(6)];

//   if ((visitors || []).length > 0 && formData.jenis !== "Regular") {
//     const filteredVisitors = visitors.filter(
//       (d: any) => d.jam === formData.jam + " WIB"
//     );
//     const booked = filteredVisitors.reduce((acc: any, val: any) => {
//       acc = acc + Number(val.total);
//       return acc;
//     }, 0);
//     listTamu = [...Array(listTamu.length - booked)];
//   }

//   const listJam =
//     formData.jenis === "Regular"
//       ? [
//           ...Array(21)
//             .fill("")
//             .map((_, i) => i),
//         ].slice(9)
//       : [11, 13];
//   const listAcara =
//     formData.jenis === "Regular"
//       ? ["Meeting", "Ulang Tahun", "Gathering", "Lain-Lain"]
//       : [];

//   useEffect(() => {
//     if (
//       formData.jenis === "Regular" &&
//       formData.tanggal &&
//       formData.jam &&
//       formData.tamu &&
//       formData.acara
//     ) {
//       setActive("detail");
//     }
//     if (
//       formData.jenis === "Jelajah Rasa" &&
//       formData.tanggal &&
//       formData.jam &&
//       formData.tamu
//     ) {
//       setActive("detail");
//     }
//   }, [formData]);

//   function handleSubmit() {
//     if (
//       !formData.tanggal ||
//       !formData.jenis ||
//       !formData.jam ||
//       !formData.tamu ||
//       !formData.name ||
//       !formData.phone ||
//       !formData.email
//     ) {
//       setShowError(true);
//       return;
//     }
//     if (formData.jenis === "Regular" && !formData.acara) {
//       setShowError(true);
//     }
//     if (!validateEmail(formData.email)) {
//       setFormValidation((p) => ({ ...p, email: false, touched: true }));
//       return;
//     }
//     setLoading(true);
//     const date = formData.tanggal ? new Date(formData.tanggal) : null;
//     fetch(
//       process.env.REACT_APP_API_URL +
//         "/forms/submit/reservation?token=d8acbd67a12b4037eef7f9a4cd692f",
//       {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({
//           form: {
//             Nama: formData.name,
//             Email: formData.email,
//             Phone: formData.phone,
//             "Jenis Reservasi": formData.jenis,
//             Tanggal: date ? date.toLocaleDateString("id-ID") : "",
//             Waktu: `${formData.jam} WIB`,
//             "Jumlah Orang": formData.tamu,
//             Acara: formData.acara || "",
//           },
//         }),
//       }
//     )
//       .then(() => {
//         setFormValidation((p) => ({ ...p, email: true, touched: true }));
//         setShowModal(true);
//       })
//       .finally(() => {
//         setLoading(false);
//       });
//   }

//   return (
//     <div className="bg-pink block-reservation" id="rel-block-reservation">
//       <div className="flex">
//         <button
//           onClick={() => setActive("reservasi")}
//           className={`w-6/12 md:pt-3 md:pb-3 p-1 text-purple text-center font-f-2 text-16 md:text-24 ${
//             active === "reservasi" ? "" : "bg-lightPink"
//           }`}
//         >
//           1. Reservasi
//         </button>
//         <button
//           onClick={() => setActive("detail")}
//           className={`w-6/12 md:pt-3 md:pb-3 p-1 text-purple text-center md:text-24 text-16  font-f-2 ${
//             active === "detail" ? "" : "bg-lightPink"
//           }`}
//         >
//           2. Detail Reservasi
//         </button>
//       </div>
//       {active === "reservasi" && (
//         <div className="pt-3 pb-4 md:pl-8 md:pr-8 md:pt-8 md:pb-12">
//           <div className="justify-between md:flex">
//             <Select
//               // options={["Regular", "Jelajah Rasa"]}
//               options={["Regular"]}
//               setSelected={(e) => {
//                 setFormData((p: any) => ({ ...p, jenis: e, acara: "" }));
//               }}
//               label="Jenis Reservasi"
//               selected={formData.jenis}
//             />
//             <div className="relative w-full pl-2 pr-2 select">
//               <div className="pb-2 text-left text-purple text-16 md:text-32">
//                 Tanggal
//               </div>
//               <div className="relative flex items-center justify-between pb-1 mb-2 border-b md:mb-0 border-purple">
//                 <span className="w-full text-purple text-16 md:text-24">
//                   <div className="custom-datepicker">
//                     <DatePicker
//                       selected={formData.tanggal}
//                       placeholderText="Pilih"
//                       minDate={new Date()}
//                       onChange={(date: any) =>
//                         setFormData((p: any) => ({ ...p, tanggal: date }))
//                       }
//                     />
//                   </div>
//                 </span>
//                 <Chevron className="absolute right-0 pointer-events-none" />
//               </div>
//             </div>
//             <Select
//               options={listJam.map((d) => `${d + 1}:00`)}
//               label="Jam"
//               setSelected={(e) => setFormData((p: any) => ({ ...p, jam: e }))}
//               selected={formData.jam}
//             />
//             <Select
//               options={listTamu.map((_: any, index: number) => `${index + 1}`)}
//               label="Jumlah Tamu"
//               setSelected={(e) =>
//                 setFormData((p: any) => ({ ...p, tamu: `${e}` }))
//               }
//               selected={formData.tamu}
//             />
//             {listAcara.length > 0 && (
//               <Select
//                 options={listAcara}
//                 setSelected={(e) =>
//                   setFormData((p: any) => ({ ...p, acara: e }))
//                 }
//                 label="Acara"
//                 selected={formData.acara}
//               />
//             )}
//           </div>
//         </div>
//       )}
//       {active === "detail" && (
//         <div className="pt-3 pb-4 md:p-8">
//           <div className="items-end justify-between md:flex">
//             <Input
//               value={formData.name}
//               onChange={(e) => setFormData((p: any) => ({ ...p, name: e }))}
//               label="Nama"
//             />
//             <Input
//               value={formData.phone}
//               onChange={(e) => setFormData((p: any) => ({ ...p, phone: e }))}
//               label="No. Telepon"
//             />
//             <Input
//               value={formData.email}
//               note={
//                 formValidation.touched && !formValidation.email
//                   ? "Email format invalid"
//                   : ""
//               }
//               onChange={(e) => setFormData((p: any) => ({ ...p, email: e }))}
//               label="Alamat Email"
//             />
//             <button
//               style={{ paddingLeft: 64, paddingRight: 64 }}
//               onClick={handleSubmit}
//               disabled={loading}
//               // disabled
//               // className="pt-1 pb-1 pl-4 pr-4 mt-2 text-white cursor-default  opacity-30 md:mt-0 md:pt-2 md:pb-2 text-14 md:text-21 bg-purple"
//               className="pt-1 pb-1 pl-4 pr-4 mt-2 text-white md:mt-0 md:pt-2 md:pb-2 text-14 md:text-21 bg-purple"
//             >
//               {loading ? "Loading.." : "Konfirmasi"}
//             </button>
//           </div>
//         </div>
//       )}
//       {showError && (
//         <div className="fixed top-0 bottom-0 left-0 right-0 z-50 flex items-center justify-center text-center bg-black bg-opacity-70">
//           <div>
//             <div className="pt-3 pb-3 pl-3 pr-3 bg-pink">
//               <div className="pl-6 pr-6 mt-3 mb-5 text-21">
//                 Mohon lengkapi data reservasi anda
//               </div>
//               <button
//                 onClick={() => {
//                   setShowError(false);
//                 }}
//                 className="pt-1 pb-1 pl-5 pr-5 text-white bg-purple text-21"
//               >
//                 Tutup
//               </button>
//             </div>
//           </div>
//         </div>
//       )}
//       {showModal && (
//         <div className="fixed top-0 bottom-0 left-0 right-0 z-50 flex items-center justify-center text-center bg-black bg-opacity-70">
//           <div>
//             <div className="pt-3 pb-3 text-48 bg-lightPink">Terima Kasih</div>
//             <div className="pt-3 pb-3 pl-3 pr-3 bg-pink">
//               <div className="pl-6 pr-6 mt-3 mb-5 text-21">
//                 Reservasi Anda telah kami terima, harap <br />
//                 menunggu email balasan dari kami.
//               </div>
//               <button
//                 onClick={() => {
//                   setShowModal(false);
//                   setFormData({
//                     jenis: "",
//                     tanggal: "",
//                     jam: "",
//                     tamu: "",
//                     acara: "",
//                     name: "",
//                     phone: "",
//                     email: "",
//                   });
//                 }}
//                 className="pt-1 pb-1 pl-5 pr-5 text-white bg-purple text-21"
//               >
//                 Selesai
//               </button>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// }
