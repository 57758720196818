import { ReactNode, useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import useData from "../hooks/useData";

export default function Layout(props: { children: ReactNode }) {
  const [showModal, setShowModal] = useState(false);
  const { data } = useData("/singletons/get/page_setting");
  useEffect(() => {
    if (data?.pup_up_message && data?.pop_up) {
      setShowModal(true);
      return;
    }
  }, [data]);
  return (
    <div>
      <Header />
      <div className="bg-purple">{props.children}</div>
      <div className="pb-3">
        <Footer />
      </div>
      {
        // <div className="fixed bottom-0 left-0 hidden w-full py-1 bg-pink text-16 md:text-24">
        //   {/*@ts-ignore */}
        //   <marquee behavior="scroll" direction="left">
        //     Jiva Bestari untuk sementara ditutup selama perpanjangan masa PPKM
        //     {/*@ts-ignore */}
        //   </marquee>
        // </div>
      }
      {showModal && (
        <div className="fixed top-0 bottom-0 left-0 right-0 z-50 flex items-center justify-center text-center bg-black bg-opacity-70">
          <div>
            <div className="pt-3 pb-3 pl-3 pr-3 ml-3 mr-3 bg-pink">
              <div className="md:pl-6 md:pr-6 mt-3 mb-5 text-21">
                {data?.pup_up_message || ""}
              </div>
              <button
                onClick={() => {
                  setShowModal(false);
                  window.sessionStorage.setItem(
                    "pop_up_message",
                    data.pup_up_message
                  );
                }}
                className="pt-1 pb-1 pl-5 pr-5 text-white bg-purple text-21"
              >
                Tutup
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
