import { ReactNode, useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import { Spring, animated } from "react-spring/renderprops";

export default function Reveal(props: {
  children: ReactNode;
  delay?: number;
  opacityOnly?: boolean;
  notOnce?: boolean;
}) {
  const [active, setActive] = useState(true);
  return (
    <VisibilitySensor
      active={props.notOnce || active}
      partialVisibility
      onChange={(isVisible) => isVisible && setActive(false)}
    >
      {({ isVisible }) => (
        <Spring
          native
          delay={props.delay || 100}
          config={{ tension: 280, friction: 60 }}
          to={{
            opacity: isVisible ? 1 : 0,
            transform: isVisible
              ? "translate3d(0,0px,0)"
              : "translate3d(0,40px,0)",
          }}
        >
          {({ opacity, transform }) => (
            <animated.div
              style={{
                opacity,
                transform: props.opacityOnly ? "none" : transform,
              }}
            >
              {props.children}
            </animated.div>
          )}
        </Spring>
      )}
    </VisibilitySensor>
  );
}
