import { useState, useEffect } from "react";

let memcached: any = {};

const initialData = (url: string) =>
  fetch(process.env.REACT_APP_API_URL + url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

export default function useData(url: string, options?: any) {
  const [data, setData] = useState<any>({
    data: memcached[url] || null,
    loading: memcached[url] ? false : true,
  });
  useEffect(() => {
    if (memcached[url]) return;
    if (options) {
      fetch(process.env.REACT_APP_API_URL + url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(options),
      })
        .then(async (res) => {
          const json = await res.json();
          setData({ data: json, loading: false });
        })
        .catch(() => {
          setData({ data: null, loading: false });
        });
      return;
    }
    initialData(url)
      .then(async (res) => {
        const json = await res.json();
        memcached[url] = json;
        setData({ data: json, loading: false });
      })
      .catch(() => {
        setData({ data: null, loading: false });
      });
  }, [url]);
  return data;
}
