import { ReactComponent as ArrowBottom } from "../svg/long-arrow-bottom.svg";
import { useSpring, config } from "react-spring";

export default function GoToReservation() {
  const [y, setY] = useSpring(() => ({
    immediate: true,
    config: config.default,
    y: 0,
    onFrame: (props: any) => {
      window.scroll(0, props.y);
    },
  }));
  return (
    <>
      <button
        className="absolute left-0 hidden md:block"
        style={{ marginTop: -90, marginLeft: 100 }}
      >
        <ArrowBottom />
      </button>
      <button
        className="absolute z-10 right-0 hidden pt-3 pb-3 md:block bg-pink text-24 font-f-2"
        onClick={() => {
          const el: any = document.querySelectorAll(".block-reservation");
          const sel = el[1] || el[0];
          setY({ y: sel.offsetTop });
        }}
        style={{ width: 300, marginTop: -50 }}
      >
        Reservasi
      </button>
    </>
  );
}
