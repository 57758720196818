import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./Home";
import Menu from "./Menu";
import JelajahJivaBestari from "./Jelajah-jiva-bestari";
import InfoSekolah from "./InfoSekolah";
import HubungiKami from "./HubungiKami";
import Layout from "./components/Layout";

function App() {
  useEffect(() => {
    const w: any = window;
    // window.onload(() => {
    var counter = 0;
    var c = 0;
    var divcounter: any = document.getElementById("html-counter");
    if (!divcounter) return;
    var i = setInterval(function () {
      divcounter.innerText = c + "%";
      //$(".loading-page .counter").css("background", "linear-gradient(to right, #f60d54 "+ c + "%,#0d0d0d "+ c + "%)");

      /*
    $(".loading-page .counter h1.color").css("width", c + "%");
    */
      counter++;
      c++;

      if (counter == 101) {
        clearInterval(i);
        const loading: any = document.getElementById("initial-loading");
        loading.classList.add("hide-loader");
      }
    }, 30);
    // });
  }, []);
  return (
    <Router>
      <Layout>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/menu" component={Menu} />
          <Route exact path="/info-sekolah" component={InfoSekolah} />
          <Route exact path="/hubungi-kami" component={HubungiKami} />
          <Route
            exact
            path="/jelajah-rasa-dewantara"
            component={JelajahJivaBestari}
          />
        </Switch>
      </Layout>
    </Router>
  );
}

export default App;
