import Reservation from "./components/Reservation";
import Reveal from "./components/AnimReveal";
import Accordion from "./components/Accordion";
import GoToReservation from "./components/GoToReservation";
import { useState } from "react";
import useData from "./hooks/useData";
import ErrorPage from "./components/ErrorPage";

export default function Menu() {
  const [showMenu, setShowMenu] = useState("");
  const page = useData("/singletons/get/page_menu");
  const menus = useData("/collections/get/menu");
  const categories = useData("/collections/get/category", {
    sort: { _created: -1 },
  });
  if (page.loading || menus.loading || categories.loading) return null;
  if (!page.loading && !page.data) return <ErrorPage />;
  const listmenus: any = (categories.data.entries || []).map((cat: any) => {
    const findMenus = (menus.data.entries || []).filter(
      (d: any) => d.category._id === cat._id
    );
    return {
      label: cat.name,
      gallery: (cat.gallery || []).map((d: any) => ({
        img: d.path,
        desc: d.meta.title || "",
      })),
      menus: findMenus.map((men: any) => {
        return {
          title: men.name || "",
          price: men.price || "",
          desc: men.description || "",
        };
      }),
    };
  });
  return (
    <div>
      <div className="bg-purple">
        <div
          className="bg-center bg-cover"
          style={{
            backgroundImage: `url(${
              process.env.REACT_APP_BASE_URL + page.data.banner.path
            })`,
            height: "calc(100vh - 50px)",
          }}
        />
        <GoToReservation />
        <div className="container pt-3 pb-5 pl-2 pr-2 text-center text-white md:pt-12 md:pb-30">
          <div className="pb-4 md:mb-3 text-32 md:text-48 font-f-2">
            <Reveal>Menu</Reveal>
          </div>
          <Reveal>
            <hr />
          </Reveal>
          {listmenus.map((menu: any, index: number) => {
            return (
              <Accordion
                show={showMenu === menu.label}
                onChange={setShowMenu}
                {...menu}
                key={index}
              />
            );
          })}
        </div>
      </div>
      <Reservation />
    </div>
  );
}
