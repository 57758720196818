export default function ErrorPage() {
  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 flex items-center justify-center bg-purple">
      <div className="text-center text-white">
        <div className="text-64">Error 500</div>
        <div className="mb-4 text-21">Something just isn't right.. </div>
        <button
          className="p-1 pl-4 pr-4 hover:bg-pink border border-white"
          onClick={() => window.location.reload()}
        >
          Refresh
        </button>
      </div>
    </div>
  );
}
